import _ from 'lodash';

import { NodeIds } from '@breathelife/insurance-form-builder';
import { QuestionnaireEngine } from '@breathelife/questionnaire-engine';
import {
  CollectionInstanceIdentifiers,
  QuestionnaireVersionPricingNodeIds,
  VersionedAnswers,
} from '@breathelife/types';
import { AdoMinMaxConfig } from '../../Models/PlatformFeatures';

export type ShouldFetchADOParams = {
  answers: VersionedAnswers;
  featureConfigsAdoMinMax: AdoMinMaxConfig;
  updatedNodeIds: string[];
  currentProposedInsuredIndex: number;
  questionnaireEngine: QuestionnaireEngine;
  pricingFieldIdentifiers?: QuestionnaireVersionPricingNodeIds;
};

// Checks whether the conditions are met to fetch ADO values
export function shouldFetchADO({
  answers,
  updatedNodeIds,
  featureConfigsAdoMinMax,
  currentProposedInsuredIndex,
  questionnaireEngine,
  pricingFieldIdentifiers,
}: ShouldFetchADOParams): boolean {
  if (!featureConfigsAdoMinMax.enabled) return false;

  const fieldIdentifiersToWatch = pricingFieldIdentifiers
    ? pricingFieldIdentifiers.nodeIds
    : featureConfigsAdoMinMax.nodeIdsAffectingAdoMinMax;

  const shouldRefreshAdo =
    _.isArray(fieldIdentifiersToWatch) && _.intersection(fieldIdentifiersToWatch, updatedNodeIds).length > 0;
  const adoOptionNodeId = featureConfigsAdoMinMax.adoOptionNodeId;

  if (!adoOptionNodeId || !shouldRefreshAdo) return false;
  const collectionInstanceIdentifiers: CollectionInstanceIdentifiers = {
    [NodeIds.insuredPeople]: currentProposedInsuredIndex,
  };

  const adoOption = questionnaireEngine.getAnswer(answers, adoOptionNodeId, collectionInstanceIdentifiers);
  if (!adoOption || adoOption === 'no') return false;

  return true;
}
