import { ReactElement, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Localizable } from '@breathelife/types';
import { Box, Grid } from '@breathelife/mui';
import { TextInput } from '@breathelife/ui-components';

import { SubmitButton } from '../../../Components/Button/SubmitButton';
import { ModalLayout } from '../../../Layouts/Modal/ModalLayout';
import { useCarrierContext } from '../../../Hooks/useCarrierContext';
import { useFetchAllLinesOfBusinessQuery } from '../../../ReactQuery/Admin/LinesOfBusiness/linesOfBusiness.queries';
import { useLocale } from '../../../Hooks';
import { Select } from '../../../Components/Select/Select';
import { useCreateQuestionnaireMutation } from '../../../ReactQuery/Admin/Questionnaire/questionnaire.mutations';
import { generateQuestionnaireUrl } from '../../../Navigation/Urls';
import { queryClient } from '../../../ReactQuery';
import { QueryId } from '../../../ReactQuery/common/common.types';

type Props = {
  isOpen: boolean;
  onCancel: () => void;
};

export function CreateQuestionnaireModal(props: Props): ReactElement {
  const { t } = useTranslation();
  const {
    languageSettings: { enabledLanguages },
  } = useCarrierContext();
  const language = useLocale();
  const navigate = useNavigate();

  const { data: linesOfBusiness } = useFetchAllLinesOfBusinessQuery();
  const { mutate: createQuestionnaireMutation } = useCreateQuestionnaireMutation();

  const defaultLocalizable = enabledLanguages.reduce(
    (obj, language) => ({ ...obj, [language]: '' }),
    {},
  ) as Localizable;
  const [name, setName] = useState<Localizable>(defaultLocalizable);
  const [lineOfBusiness, setLineOfBusiness] = useState<string>('');

  const onCreateQuestionnaire = (): void => {
    createQuestionnaireMutation(
      {
        lineOfBusinessId: lineOfBusiness,
        name,
      },
      {
        onSuccess: (data) => {
          props.onCancel();
          void queryClient.invalidateQueries([QueryId.allQuestionnaires]);
          navigate(generateQuestionnaireUrl(data.id));
        },
      },
    );
  };

  const linesOfBusinessOptions = useMemo(() => {
    return (
      linesOfBusiness
        ?.map((questionnaire) => {
          return {
            value: questionnaire.id,
            label: questionnaire.name[language] as string,
          };
        })
        .sort((a, b) => a.label.localeCompare(b.label)) || []
    );
  }, [linesOfBusiness, language]);

  const submitButtonIsDisabled = !lineOfBusiness || Object.values(name).some((x) => x === '');
  const submitButton = (
    <SubmitButton onClick={onCreateQuestionnaire} disabled={submitButtonIsDisabled}>
      {t('admin.questionnaireManagement.createQuestionnaireModal.submitLabel')}
    </SubmitButton>
  );

  return (
    <ModalLayout
      isOpen={props.isOpen}
      title={t('admin.questionnaireManagement.createQuestionnaireModal.title')}
      submitButton={submitButton}
      closeModal={props.onCancel}
      maxWidth='sm'
    >
      <Grid container spacing={2} alignItems='center'>
        {enabledLanguages.map((language) => (
          <Grid item xs={6} key={`createQuestionnaire-name-${language}`}>
            <TextInput
              name={`new-questionnaire-name-${language}`}
              inputVariant='outlined'
              label={t('admin.questionnaireManagement.createQuestionnaireModal.nameLanguageLabel', {
                language: t(`language.${language}`),
              })}
              value={name[language] ?? ''}
              onChange={(event) => {
                const value = event.target.value;
                setName((previousValue) => ({ ...previousValue, [language]: value }));
              }}
            />
          </Grid>
        ))}
      </Grid>
      <Box mt={2} width='100%'>
        <Select
          id='new-questionnaire-select'
          label={t('admin.questionnaireManagement.createQuestionnaireModal.lineOfBusinessLabel')}
          value={lineOfBusiness}
          options={linesOfBusinessOptions}
          onChange={(value) => {
            setLineOfBusiness(value);
          }}
        />
      </Box>
    </ModalLayout>
  );
}
