import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../../Styles/themed-styled-components';

import { ProductsEntity } from '@breathelife/types';
import { ImageTypes, LoaderOverlay } from '@breathelife/ui-components';

import Typography from '../../../../Components/Typography';
import getCurrency from '../../../../Helpers/currency';
import { useCarrierContext } from '../../../../Hooks';
import { toCurrency } from '../../../../Localization/utils';
import { Icon } from '../../../Icons';
import {
  CardBody,
  CardContainer,
  CardEmpty,
  CardEmptyImg,
  CardFooter,
  CardHeader,
  CardProduct,
  CardProducts,
  CardRider,
  CardRiderContent,
  CardRiders,
  Dot,
} from './Styles';

type Props = {
  productsEntity: ProductsEntity<string>;
  isLoading: boolean;
};

export function ProductWidget(props: Props): ReactElement {
  const { productsEntity, isLoading } = props;
  const { countryCode } = useCarrierContext();
  const { t } = useTranslation();
  const theme = useTheme();

  const hasProducts = productsEntity.products.length > 0;
  const hasRiders = productsEntity.riders.length > 0;
  const isProductsAndRidersEmpty = !hasProducts && !hasRiders;

  return (
    <CardContainer>
      <CardHeader>
        <Typography variant='h3' component='h2'>
          {t('assistedApplication.productWidget.title')}
        </Typography>
      </CardHeader>

      {isProductsAndRidersEmpty && (
        <LoaderOverlay isLoading={isLoading}>
          <CardEmpty>
            <CardEmptyImg>
              <Icon name='documentWithCheck' imageType={ImageTypes.decorative} />
            </CardEmptyImg>
            <Typography variant='body1' color={theme.colors.grey[60]}>
              {t('assistedApplication.productWidget.empty')}
            </Typography>
          </CardEmpty>
        </LoaderOverlay>
      )}

      {!isProductsAndRidersEmpty && (
        <LoaderOverlay isLoading={isLoading}>
          <CardBody>
            <Typography variant='body4' component='h3' color={theme.colors.grey[60]}>
              {t('assistedApplication.productWidget.products')}
            </Typography>

            {hasProducts && (
              <CardProducts>
                {productsEntity.products.map((product) => (
                  <CardProduct key={product.productId}>
                    <div>
                      <Typography variant='body4' component='p'>
                        {product.name}
                      </Typography>
                      <Typography variant='body5' component='p' color={theme.colors.grey[70]}>
                        {product.coverageAmount
                          ? toCurrency(product.coverageAmount, getCurrency(countryCode), { minimumFractionDigits: 0 })
                          : '--'}
                      </Typography>
                    </div>
                    <Typography variant='body5' className='productPremium'>
                      {product.premium
                        ? toCurrency(product.premium, getCurrency(countryCode), { minimumFractionDigits: 2 })
                        : '--'}
                    </Typography>
                  </CardProduct>
                ))}
              </CardProducts>
            )}

            {hasRiders && (
              <CardRiders>
                {productsEntity.riders.map((rider) => (
                  <CardRider key={rider.productId}>
                    <Dot />
                    <CardRiderContent>
                      <div>
                        <Typography variant='body4' component='p'>
                          {rider.name}
                        </Typography>
                        <Typography variant='body5' component='p' color={theme.colors.grey[70]}>
                          {rider.coverageAmount
                            ? toCurrency(rider.coverageAmount, getCurrency(countryCode), { minimumFractionDigits: 0 })
                            : '--'}
                        </Typography>
                      </div>
                      <Typography variant='body5'>
                        {rider.premium
                          ? toCurrency(rider.premium, getCurrency(countryCode), { minimumFractionDigits: 2 })
                          : '--'}
                      </Typography>
                    </CardRiderContent>
                  </CardRider>
                ))}
              </CardRiders>
            )}
          </CardBody>
          <CardFooter>
            <Typography variant='body4' component='p'>
              {t('assistedApplication.productWidget.totalPremium')}
            </Typography>
            {productsEntity.totalPremium ? (
              <p>
                <Typography variant='h2' component='span'>
                  {toCurrency(productsEntity.totalPremium, getCurrency(countryCode), { minimumFractionDigits: 2 })}
                </Typography>
                <Typography variant='small1' component='span'>
                  {t(`assistedApplication.productWidget.paymentFrequency.${productsEntity.paymentFrequency}`)}
                </Typography>
              </p>
            ) : (
              <Typography variant='h2' component='p'>
                --
              </Typography>
            )}
          </CardFooter>
        </LoaderOverlay>
      )}
    </CardContainer>
  );
}
