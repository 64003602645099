import { ReactElement, createContext, PropsWithChildren, useMemo } from 'react';

import {
  QuestionnaireNodeIds,
  NodeIdDetailsByRepeatability,
  generateNodeIdsForAllQuestionnaires,
} from '../../../Helpers/questionnaireEditor/questionnaireNodeIds';
import { useFetchAllCarrierQuestionnairesQuery } from '../../../ReactQuery/CarrierQuestionnaires/carrierQuestionnaire.queries';

type QuestionnaireNodeIdsContextValue = {
  nodeIdsForAllQuestionnaires: QuestionnaireNodeIds;
};

const emptyNodeIdDetailsByRepeatability: NodeIdDetailsByRepeatability = {
  allLeafs: [],
  noRepeatability: [],
  withRepeatableAncestor: {},
  collection: [],
};

const initialValue: QuestionnaireNodeIdsContextValue = {
  nodeIdsForAllQuestionnaires: {
    inQuestionnaire: emptyNodeIdDetailsByRepeatability,
    notInQuestionnaire: emptyNodeIdDetailsByRepeatability,
    selectOptionsByNodeId: {},
  },
};

export const AllQuestionnairesNodeIdsContext = createContext<QuestionnaireNodeIdsContextValue>(initialValue);

export function AllQuestionnairesNodeIdsContextProvider({
  children,
}: PropsWithChildren<Record<string, unknown>>): ReactElement {
  const { data: carrierQuestionnaires } = useFetchAllCarrierQuestionnairesQuery();

  const nodeIdsForAllQuestionnaires = useMemo(() => {
    if (carrierQuestionnaires) {
      const allQuestionnaires = carrierQuestionnaires.map((carrierQuestionnaire) => {
        return carrierQuestionnaire.questionnaire;
      });
      return generateNodeIdsForAllQuestionnaires(allQuestionnaires);
    }
    return initialValue.nodeIdsForAllQuestionnaires;
  }, [carrierQuestionnaires]);

  return (
    <AllQuestionnairesNodeIdsContext.Provider value={{ nodeIdsForAllQuestionnaires }}>
      {children}
    </AllQuestionnairesNodeIdsContext.Provider>
  );
}
