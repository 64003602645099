import _ from 'lodash';

import { Permission, User, UserFields } from '@breathelife/types';

// Make sure to keep this list in sync with the list in the backend
export const defaultSsoProfileFields: UserFields = ['firstName', 'lastName', 'emailLogin', 'category', 'type', 'roles'];

export function userHasPermission(userPermissions: Permission[] = [], permissions: Permission | Permission[]): boolean {
  let hasPermission;
  if (Array.isArray(permissions)) {
    hasPermission = _.intersection(userPermissions, permissions).length > 0;
  } else {
    hasPermission = userPermissions.includes(permissions);
  }
  return hasPermission;
}

export function getUserDisplayName(user: User): string {
  let displayName = user.emailLogin || '';

  if (user.lastName) {
    if (user.preferredName) {
      displayName = `${user.preferredName} ${user.lastName}`;
    } else if (user.firstName) {
      displayName = `${user.firstName} ${user.lastName}`;
    }
  }

  return displayName;
}
