import { NotificationTypes } from '@breathelife/types';

import { Translation } from '../../../Localization';

const translation: Translation = {
  needsAnalysis: 'Invitations',
  questionnaire: 'Questionnaire',
  cta: {
    sendNeedsAnalysis: "Envoyer l'invitation",
    resendNeedsAnalysis: "Renvoyer l'invitation",
    needAnalysisDetails: 'Détails du questionnaire',
  },
  modals: {
    sendEmail: {
      selectNeedsAnalysis: "Sélectionner l'invitation que vous désirez envoyer:",
      willBeSentTo: 'Une invitation sera envoyée à:',
    },
  },
  notificationPreferences: {
    emails: {
      [NotificationTypes.leadCompletedNeedsAnalysisEmail]: 'Contactez-moi quand un contact complète le Questionnaire',
    },
  },
  tabs: {
    needsAnalysis: {
      sent: 'Invitation envoyée',
      notSent: {
        notSentYet: "Aucun questionnaire n'a encore été envoyé à ce contact",
      },
    },
  },
  product: {
    missingNeedsAnalysisInformation:
      "Il manque des informations pour confirmer l'éligibilité et calculer le prix pour ce produit. Veuillez inviter le contact à remplir le Questionnaire.",
  },
};
export default translation;
