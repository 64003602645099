import { Language, LocalizedInsuranceProduct } from '@breathelife/types';

import { LegacyProductInfo } from '../Models/Product';
import ApiService from './ApiService';

export async function getProducts(
  applicationId: string,
  lang?: Language,
): Promise<LegacyProductInfo[] | LocalizedInsuranceProduct[]> {
  const response = await ApiService.fetchProducts(applicationId, lang);
  return response.data;
}
