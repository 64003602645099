import _ from 'lodash';
import { ReactElement, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Box, Grid } from '@breathelife/mui';
import { Permission, QuestionnaireBlueprintCopyableOption } from '@breathelife/types';

import { SubmitButton } from '../../../Components/Button/SubmitButton';
import { Checkbox, CheckboxGroup } from '../../../Components/Checkbox/Checkbox';
import { RestrictedToUserPermissions } from '../../../Components/Restricted/RestrictedToUserPermissions';
import Typography from '../../../Components/Typography';
import { CarrierContext } from '../../../Context/CarrierContext';
import { ModalLayout } from '../../../Layouts/Modal/ModalLayout';
import { Application } from '../../../Models/Application';
import { useCopyApplicationMutation } from '../../../ReactQuery/Application/application.mutations';
import { generateLeadsListUrl } from '../../../Navigation/Urls';

export type Props = {
  isOpen: boolean;
  closeModal: () => void;
  application: Application;
};

export function CopyApplicationConfirmationModal(props: Props): ReactElement {
  const { t } = useTranslation();
  const { isOpen, closeModal, application } = props;
  const { features } = useContext(CarrierContext);
  const navigate = useNavigate();

  const [selectedSections, setSelectedSections] = useState<QuestionnaireBlueprintCopyableOption[]>([]);
  const [validationError, setValidationError] = useState<string>('');

  const sectionChoices = useMemo(() => {
    if (!features?.applicationCopy?.enabled) return [];
    return [QuestionnaireBlueprintCopyableOption.insured, QuestionnaireBlueprintCopyableOption.owner];
  }, [features?.applicationCopy]);

  useEffect(() => {
    setSelectedSections(sectionChoices);
  }, [sectionChoices]);

  useEffect(() => {
    const enabledSectionsList = Object.keys(selectedSections).filter((_section, index) => selectedSections[index]);
    if (enabledSectionsList.length === 0) {
      setValidationError(t('modals.copyApplication.copyApplicationMissingSectionsError'));
    } else {
      setValidationError('');
    }
  }, [t, selectedSections]);

  const copyApplicationMutation = useCopyApplicationMutation({
    onSuccess: (data) => {
      closeModal();
      setSelectedSections(sectionChoices);
      data.leadId && navigate(generateLeadsListUrl(data.leadId));
    },
  });

  function copyApplication(): void {
    copyApplicationMutation.mutate({ application, copyableOptions: selectedSections });
  }

  return (
    <ModalLayout
      maxWidth='sm'
      isOpen={isOpen}
      closeModal={closeModal}
      title={t('cta.copyApplication')}
      submitButton={
        <RestrictedToUserPermissions requiredPermissions={[Permission.ApplicationCopyCreate]}>
          <SubmitButton
            onClick={copyApplication}
            data-testid='copy-application-confirm'
            disabled={Boolean(validationError)}
          >
            {t('cta.copy')}
          </SubmitButton>
        </RestrictedToUserPermissions>
      }
    >
      <Box mt={2} mb={1}>
        <Typography display='block' variant='body1' grey={90}>
          {t('modals.copyApplication.copyApplicationChooseContent', {
            refNoIncrement: application.refNoIncrement,
          })}
        </Typography>
        <CheckboxGroup validationError={validationError}>
          <Box my={2}>
            <Grid container direction='column' spacing={1}>
              {_.map(sectionChoices, (section) => {
                const isOptionSelected = selectedSections.includes(section);
                return (
                  <Grid item key={section}>
                    <Checkbox
                      color='primary'
                      label={t(`modals.copyApplication.${section}`)}
                      checked={isOptionSelected}
                      onChange={() => {
                        setSelectedSections((prev) =>
                          isOptionSelected ? prev.filter((value) => value !== section) : [...prev, section],
                        );
                      }}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        </CheckboxGroup>
      </Box>
    </ModalLayout>
  );
}
