import _ from 'lodash';
import { ReactElement, useContext, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { NodeIds } from '@breathelife/insurance-form-builder';
import { Box, Grid } from '@breathelife/mui';
import {
  BlueprintConditionValue,
  BooleanOperator,
  ConsiderationBlueprint,
  OutcomeCode,
  SalesDecisionRule,
} from '@breathelife/types';

import { DetailViewType } from '../../../Models/Layout';
import styled from '../../../Styles/themed-styled-components';
import Typography from '../../../Components/Typography';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../Components/Button/Button';
import { CloseButton } from '../../../Components/Button/CloseButton';
import {
  QuestionnaireVersionDataContext,
  useQuestionnaireVersionDetailWithNodeIdInfo,
} from '../Questionnaire/ContextProvider/QuestionnaireVersionDataContextProvider';
import { Drawer } from '../../../Components/Drawer/Drawer';
import { useCarrierContext, useLocale, useSelector } from '../../../Hooks';
import { ConditionsEditor, defaultSingleConditionBlueprintValue } from '../../../Components/Conditions';
import { OutcomeSelect } from './SalesDecisionRuleDetail/OutcomeSelect';
import { ReasonInput } from './SalesDecisionRuleDetail/ReasonInput';
import { RuleIdentifierInput } from './SalesDecisionRuleDetail/RuleIdentifierInput';
import {
  addConditionToBlueprint,
  addNestedConditionToBlueprint,
  removeConditionFromBlueprint,
  updateConditionBlueprint,
  updateConditionBooleanOperator,
} from '../../../Helpers/conditions/blueprintHelpers';
import { validateBlueprint, validateRuleIdentifier } from '../../../Helpers/inputValidation/form/salesDecisionRules';
import * as SalesDecisionRuleManagementOperations from '../../../ReduxStore/Admin/SalesDecisionRulesManagement/SalesDecisionRulesManagementOperations';
import { QuestionnaireVersionsContext } from '../Questionnaire/ContextProvider/QuestionnaireVersionsContextProvider';

const Header = styled(Box)`
  background-color: ${(props) => props.theme.colors.grey[20]};
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 8px 10px;
  margin-top: 8px;
`;

type Props = {
  selectedRule: SalesDecisionRule | null;
  onDrawerClose: () => void;
};

export function SalesDecisionRuleDrawer({ selectedRule, onDrawerClose }: Props): ReactElement | null {
  const { t } = useTranslation();
  const language = useLocale();
  const { features } = useCarrierContext();
  const dispatch = useDispatch();

  const { questionnaireNodeIds, nodeIdInCollectionMap } = useQuestionnaireVersionDetailWithNodeIdInfo() || {};
  const { selectedQuestionnaireId } = useContext(QuestionnaireVersionsContext);
  const { questionnaireVersionId } = useContext(QuestionnaireVersionDataContext);

  const { isOpen, type: viewType } = useSelector((store) => store.leadPlatform.layout.rightPanelState);

  const defaultBlueprintState = useMemo(
    () => ({
      outcomeCode: OutcomeCode.referred,
      reason: '',
      conditions: {
        conditions: [defaultSingleConditionBlueprintValue()],
        booleanOperator: BooleanOperator.and,
      },
    }),
    [],
  );

  const [blueprint, setBlueprint] = useState<ConsiderationBlueprint>(defaultBlueprintState);
  const [ruleIdentifier, setRuleIdentifier] = useState<string>('');
  const [hasUpdate, setHasUpdate] = useState<boolean>(false);

  const formState = { ...blueprint, identifier: ruleIdentifier };

  useEffect(() => {
    if (selectedRule) {
      setBlueprint(selectedRule.blueprint);
      setRuleIdentifier(selectedRule.identifier || '');
    } else {
      setBlueprint(defaultBlueprintState);
      setRuleIdentifier('');
    }
  }, [selectedRule, defaultBlueprintState]);

  const isFormValid = useMemo(() => {
    if (!blueprint || !questionnaireNodeIds || !nodeIdInCollectionMap || !selectedQuestionnaireId) {
      return false;
    }
    const valid =
      blueprint.outcomeCode &&
      !_.isEmpty(blueprint.reason) &&
      validateBlueprint(blueprint.conditions, questionnaireNodeIds, nodeIdInCollectionMap) &&
      validateRuleIdentifier(ruleIdentifier, features.requireSalesDecisionRuleIdentifier?.enabled);
    return valid;
  }, [
    blueprint,
    questionnaireNodeIds,
    nodeIdInCollectionMap,
    ruleIdentifier,
    features.requireSalesDecisionRuleIdentifier,
    selectedQuestionnaireId,
  ]);

  const onUpdate = useCallback(() => {
    if (!hasUpdate) {
      setHasUpdate(true);
    }
  }, [setHasUpdate, hasUpdate]);

  const onBlueprintUpdate = useCallback((data: Partial<ConsiderationBlueprint>) => {
    setBlueprint((prevState) => {
      return {
        ...prevState,
        ...data,
      };
    });
    onUpdate();
  }, []);

  const onBooleanOperatorChange = useCallback((booleanOperator: BooleanOperator, path: string) => {
    setBlueprint((prevState) => {
      const updatedCondition = updateConditionBooleanOperator(prevState.conditions, path, booleanOperator);
      return { ...prevState, conditions: updatedCondition };
    });
    onUpdate();
  }, []);

  const onAddCondition = useCallback((path: string) => {
    setBlueprint((prevState) => {
      const updatedCondition = addConditionToBlueprint(prevState.conditions, path);
      return { ...prevState, conditions: updatedCondition };
    });
    onUpdate();
  }, []);

  const onAddNestedCondition = useCallback((path: string) => {
    setBlueprint((prevState) => {
      const updatedCondition = addNestedConditionToBlueprint(prevState.conditions, path);
      return { ...prevState, conditions: updatedCondition };
    });
    onUpdate();
  }, []);

  const onRemoveCondition = useCallback((path: string) => {
    setBlueprint((prevState) => {
      const updatedCondition = removeConditionFromBlueprint(prevState.conditions, path);
      return { ...prevState, conditions: updatedCondition };
    });
    onUpdate();
  }, []);

  const onConditionChange = useCallback((data: BlueprintConditionValue, path: string) => {
    setBlueprint((prevState) => {
      const updatedCondition = updateConditionBlueprint(prevState.conditions, path, data);
      return { ...prevState, conditions: updatedCondition };
    });
    onUpdate();
  }, []);

  const onRuleIdentifierUpdate = useCallback((data: string) => {
    setRuleIdentifier(data);
    onUpdate();
  }, []);

  const onClose = useCallback(() => {
    onDrawerClose();
    setHasUpdate(false);
  }, [setHasUpdate, onDrawerClose]);

  const onSave = useCallback(() => {
    if (!isFormValid) return;

    if (viewType === DetailViewType.create && !selectedRule && questionnaireVersionId) {
      void dispatch(
        SalesDecisionRuleManagementOperations.createRule(questionnaireVersionId, blueprint, ruleIdentifier),
      );
    } else if (selectedRule) {
      void dispatch(SalesDecisionRuleManagementOperations.updateRule(selectedRule.id, blueprint, ruleIdentifier));
    }

    onClose();
  }, [dispatch, isFormValid, blueprint, onClose, selectedRule, questionnaireVersionId, viewType, ruleIdentifier]);

  const onDelete = useCallback(() => {
    if (!selectedRule) return;
    void dispatch(SalesDecisionRuleManagementOperations.deleteRule(selectedRule.id));
    onClose();
  }, [dispatch, selectedRule, onClose]);

  if (!isOpen || !questionnaireNodeIds || !nodeIdInCollectionMap) return null;

  return (
    <Drawer open={isOpen} onClose={onClose} width='50%'>
      <Box display='flex' flexDirection='column' justifyContent='space-between' height='100%'>
        <Box display='flex' justifyContent='space-between' p={3}>
          <Typography variant='h2'>
            {viewType === DetailViewType.create
              ? t('admin.salesDecisionRulesManagement.createNewSalesDecisionRule')
              : t('admin.salesDecisionRulesManagement.editSalesDecisionRule')}{' '}
          </Typography>
          <CloseButton data-testid='create-sales-decision-rule-close' onClick={onClose} />
        </Box>
        <Header display='flex' flexDirection='row'>
          <Box mr='auto'>
            {selectedRule && (
              <Button data-testid='sales-decision-rule-delete' color='primary' variant='outlined' onClick={onDelete}>
                {t('cta.delete')}
              </Button>
            )}
          </Box>
          <Box mr={2}>
            <Button color='primary' variant='outlined' onClick={onClose}>
              {t('cta.cancel')}
            </Button>
          </Box>
          <Button
            data-testid='ProductDetailView-save'
            color='primary'
            variant='contained'
            onClick={onSave}
            disabled={!isFormValid || !hasUpdate}
          >
            {t('cta.save')}
          </Button>
        </Header>

        <Box p={3} mb='auto'>
          <Grid container spacing={2}>
            <ConditionsEditor
              questionnaireNodeIds={questionnaireNodeIds}
              collectionContext={[NodeIds.insuredPeople]}
              condition={formState.conditions}
              onConditionChange={onConditionChange}
              onBooleanOperatorChange={onBooleanOperatorChange}
              onAddCondition={onAddCondition}
              onAddNestedCondition={onAddNestedCondition}
              onRemoveCondition={onRemoveCondition}
              language={language}
              nodeIdInCollectionMap={nodeIdInCollectionMap}
            />
            <OutcomeSelect value={formState.outcomeCode} onChange={onBlueprintUpdate} />
            <ReasonInput value={formState.reason} onChange={onBlueprintUpdate} />
            <RuleIdentifierInput value={formState.identifier || ''} onChange={onRuleIdentifierUpdate} />
          </Grid>
        </Box>
      </Box>
    </Drawer>
  );
}
