import { useEffect, useMemo } from 'react';

import { RenderingQuestionnaire } from '@breathelife/questionnaire-engine';

import {
  findSectionGroup,
  SectionGroupSelectionProps,
  getSectionGroupWithIndicesId,
  getSectionsForSectionGroup,
  NavigationRenderingSection,
  SectionNavigationData,
} from '../../Helpers/assistedApplication/sectionGroups';
import { SectionGroupId } from '../../Helpers/questionnaireAnswers';
import { useAAQuestionnaireVersionContext, useCarrierContext, useSelector } from '../../Hooks';
import { useAssistedApplicationContext } from '../../Hooks/useAssistedApplicationContext';

// Gets all sections for a section group with updated visited indices
export function useAllSections(sectionGroupSelectionProps: SectionGroupSelectionProps): NavigationRenderingSection[] {
  const { features } = useCarrierContext();
  const { isPaymentReadyToSubmit } = useSelector((store) => store.leadPlatform.payment);
  const { sectionGroup, visitedSectionIndicesByGroup } = sectionGroupSelectionProps;
  const { currentProposedInsuredIndex, currentSectionGroupId, proposedInsuredTabs } = useAssistedApplicationContext();

  return useMemo((): NavigationRenderingSection[] => {
    const surrogateId = proposedInsuredTabs[currentProposedInsuredIndex]?.surrogateId ?? '';
    const sectionGroupWithIndices = visitedSectionIndicesByGroup?.find(
      (group) => group.id === getSectionGroupWithIndicesId(currentSectionGroupId, surrogateId),
    );
    const paymentSectionOptions = {
      isPaymentsEnabled: features.payments.enabled ?? false,
      isPaymentReadyToSubmit,
    };

    return getSectionsForSectionGroup(
      sectionGroup.sections,
      sectionGroupWithIndices?.indices || [],
      paymentSectionOptions,
    );
  }, [
    currentProposedInsuredIndex,
    currentSectionGroupId,
    features.payments.enabled,
    isPaymentReadyToSubmit,
    proposedInsuredTabs,
    sectionGroup.sections,
    visitedSectionIndicesByGroup,
  ]);
}

// Collects all the data required to navigate to the next section
export function useNextSectionData(
  renderingQuestionnaire: RenderingQuestionnaire,
  sectionLength: number,
): SectionNavigationData | undefined {
  const { activeSectionIndex, currentProposedInsuredIndex, currentSectionGroupId, proposedInsuredTabs } =
    useAssistedApplicationContext();
  const { maxNumberOfInsured } = useAAQuestionnaireVersionContext();
  const isLastSectionSelected = activeSectionIndex === sectionLength - 1;
  const isEndOfProposedInsuredGroup = currentProposedInsuredIndex + 1 >= proposedInsuredTabs.length;

  return useMemo(() => {
    // Will not display next button if last section of the last (or only) section group
    if (isLastSectionSelected && (maxNumberOfInsured === 1 || currentSectionGroupId === SectionGroupId.contract))
      return undefined;

    // SectionGroupId should only change if this is the last section of the last proposed insured section group
    const nextSectionGroupId =
      isEndOfProposedInsuredGroup && isLastSectionSelected ? SectionGroupId.contract : currentSectionGroupId;
    let nextSectionGroup;
    let nextProposedInsuredIndex;

    // No nextProposedInsuredIndex needed
    if (nextSectionGroupId === SectionGroupId.contract) {
      nextSectionGroup = findSectionGroup(renderingQuestionnaire, nextSectionGroupId);
      nextProposedInsuredIndex = currentProposedInsuredIndex;

      // Increase nextProposedInsuredIndex if currently at the last section
    } else {
      nextProposedInsuredIndex = isLastSectionSelected ? currentProposedInsuredIndex + 1 : currentProposedInsuredIndex;
      nextSectionGroup = findSectionGroup(renderingQuestionnaire, nextSectionGroupId, nextProposedInsuredIndex);
    }

    // If next section is in new section group, set index to 0
    // Otherwise increase index by 1
    const nextSectionIndex = isLastSectionSelected ? 0 : activeSectionIndex + 1;

    // Only need sections, not the computed values
    const nextSectionGroupSections = getSectionsForSectionGroup(
      nextSectionGroup?.sections || renderingQuestionnaire[0].sections,
      [],
    );

    const nextNavigationData: SectionNavigationData = {
      index: nextSectionIndex,
      groupId: nextSectionGroupId,
      proposedInsuredIndex: nextProposedInsuredIndex,
      section: nextSectionGroupSections[nextSectionIndex],
    };

    return nextNavigationData;
  }, [
    activeSectionIndex,
    currentProposedInsuredIndex,
    currentSectionGroupId,
    isEndOfProposedInsuredGroup,
    isLastSectionSelected,
    proposedInsuredTabs,
    renderingQuestionnaire,
  ]);
}

// Collects all the data required to navigate to the previous section
export function usePreviousSectionData(
  renderingQuestionnaire: RenderingQuestionnaire,
): SectionNavigationData | undefined {
  const { activeSectionIndex, currentProposedInsuredIndex, currentSectionGroupId, proposedInsuredTabs } =
    useAssistedApplicationContext();
  const { maxNumberOfInsured } = useAAQuestionnaireVersionContext();
  const isFirstSectionSelected = activeSectionIndex === 0;

  return useMemo(() => {
    // Will not display previous button if first section of the first (or only) section group
    if (
      isFirstSectionSelected &&
      (maxNumberOfInsured === 1 ||
        (currentSectionGroupId === SectionGroupId.insuredPeople && currentProposedInsuredIndex === 0))
    )
      return undefined;

    // SectionGroupId should only change if this is the first section of the contract section group
    const previousSectionGroupId =
      currentSectionGroupId === SectionGroupId.contract && isFirstSectionSelected
        ? SectionGroupId.insuredPeople
        : currentSectionGroupId;
    let previousSectionGroup;
    let previousProposedInsuredIndex = 0;

    // No previousProposedInsuredIndex needed
    if (previousSectionGroupId === SectionGroupId.contract) {
      previousSectionGroup = findSectionGroup(renderingQuestionnaire, previousSectionGroupId);

      // Set previousProposedInsuredIndex to highest index since navigating from contract to proposed insured
    } else if (currentSectionGroupId !== previousSectionGroupId) {
      previousProposedInsuredIndex = proposedInsuredTabs.length - 1;
      previousSectionGroup = findSectionGroup(
        renderingQuestionnaire,
        previousSectionGroupId,
        proposedInsuredTabs.length - 1,
      );

      // Decrease previousProposedInsuredIndex if currently at the first section
    } else {
      previousProposedInsuredIndex = isFirstSectionSelected
        ? currentProposedInsuredIndex - 1
        : currentProposedInsuredIndex;
      previousSectionGroup = findSectionGroup(
        renderingQuestionnaire,
        previousSectionGroupId,
        previousProposedInsuredIndex,
      );
    }

    // Only need sections, not the computed values
    const previousSectionGroupSections = getSectionsForSectionGroup(
      previousSectionGroup?.sections || renderingQuestionnaire[0].sections,
      [],
    );

    // If previous section is in new section group, set index to highest available
    // Otherwise decrease index by 1
    const previousSectionLength = previousSectionGroupSections.length || 0;
    const previousSectionIndex = isFirstSectionSelected ? previousSectionLength - 1 : activeSectionIndex - 1;
    const previousNavigationData: SectionNavigationData = {
      index: previousSectionIndex,
      groupId: previousSectionGroupId,
      proposedInsuredIndex: previousProposedInsuredIndex,
      section: previousSectionGroupSections[previousSectionIndex],
    };

    return previousNavigationData;
  }, [
    activeSectionIndex,
    currentProposedInsuredIndex,
    currentSectionGroupId,
    isFirstSectionSelected,
    proposedInsuredTabs,
    renderingQuestionnaire,
  ]);
}

export function useSectionViewPresets(
  setDisplayValidationErrors: (displayValidationErrors: boolean) => void,
  section: NavigationRenderingSection | undefined,
): void {
  useEffect(() => {
    if (!section) return;
    setDisplayValidationErrors(section.visited && !section.completed);
  }, [section]);
}
