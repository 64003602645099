import { Box } from '@breathelife/mui';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { QuotedInsuranceProduct } from '@breathelife/types';

import { Alert } from '../../../../../Components/Alert/Alert';
import getCurrency from '../../../../../Helpers/currency';
import { useCarrierContext } from '../../../../../Hooks';
import { toCurrency } from '../../../../../Localization/utils';
import { Application } from '../../../../../Models/Application';
import { Lead } from '../../../../../Models/Lead';
import { QuotedProduct } from '../../../../../Models/Product';
import { Product } from './Product';
import { ProductsViewHeader } from './ProductsViewHeader';

export type CoverageAmountError = {
  messageKey: 'validation.error.coverageLimitMin' | 'validation.error.coverageLimitMax' | 'product.roundedAmount';
  amount: number;
};

export type ProductsViewProps = {
  applications: Application[];
  coverageAmount?: number;
  coverageAmountError?: CoverageAmountError;
  displayMissingNeedsAnalysisInformationMessage?: boolean;
  hasCustomCoverageAmount: boolean;
  isCoverageAmountEditable?: boolean;
  isCoverageAmountInputEnabled: boolean;
  isLoadingQuotes: boolean;
  isNeedsAnalysisTabEnabled: boolean;
  lead: Lead;
  onCoverageAmountChange?: (value: number | undefined) => void;
  onLaunchAssistedApplication: (application: Application, selectedProductId: string) => void;
  onLaunchCoBrowsing: (applicationId: string) => void;
  onLaunchRedirectToCarrier: (
    applicationId: string,
    productId: string,
    coverageAmount: number,
    premium: number,
  ) => void;
  onSendNeedsAnalysis: () => void;
  onToggleIsDeclarationSent: (applicationId: string, isDeclarationSent: boolean) => void;
  onViewNeedsAnalysisDetails: () => void;
  products: Array<QuotedProduct | QuotedInsuranceProduct>;
  recommendedCoverageAmount?: number;
  coverageAmountStep: number;
};

export function ProductsView(props: ProductsViewProps): ReactElement {
  const { t } = useTranslation();
  const { countryCode } = useCarrierContext();
  const {
    applications,
    coverageAmount,
    coverageAmountError,
    coverageAmountStep,
    hasCustomCoverageAmount,
    isCoverageAmountEditable,
    isCoverageAmountInputEnabled,
    isLoadingQuotes,
    isNeedsAnalysisTabEnabled,
    lead,
    onCoverageAmountChange,
    onLaunchAssistedApplication,
    onLaunchCoBrowsing,
    onLaunchRedirectToCarrier,
    onSendNeedsAnalysis,
    onToggleIsDeclarationSent,
    onViewNeedsAnalysisDetails,
    products,
    recommendedCoverageAmount,
  } = props;

  // TODO: handle multiple applications / multiple products
  const application = applications?.[0];

  return (
    <div>
      {isNeedsAnalysisTabEnabled && (
        <ProductsViewHeader
          isCoverageAmountEditable={isCoverageAmountEditable}
          isCoverageAmountInputEnabled={isCoverageAmountInputEnabled}
          isCoverageAmountInputInError={!!coverageAmountError}
          coverageAmount={coverageAmount}
          recommendedCoverageAmount={recommendedCoverageAmount}
          lead={lead}
          onSendNeedsAnalysis={onSendNeedsAnalysis}
          onViewNeedsAnalysisDetails={onViewNeedsAnalysisDetails}
          onCoverageAmountChange={onCoverageAmountChange}
          coverageAmountStep={coverageAmountStep}
        />
      )}
      {!!coverageAmountError && (
        <Box mt={2} mx={4}>
          <Alert severity='warning'>
            {t(coverageAmountError.messageKey, {
              amount: toCurrency(coverageAmountError.amount, getCurrency(countryCode), { minimumFractionDigits: 0 }),
            })}
          </Alert>
        </Box>
      )}
      {products.length > 0 && (
        <Box px={4}>
          {products.map((product) => {
            return (
              <Product
                key={product.id}
                lead={lead}
                product={product}
                coverageAmount={coverageAmount}
                application={application}
                onLaunchAssistedApplication={onLaunchAssistedApplication}
                onLaunchCoBrowsing={onLaunchCoBrowsing}
                onLaunchRedirectToCarrier={onLaunchRedirectToCarrier}
                onToggleIsDeclarationSent={onToggleIsDeclarationSent}
                hasCustomCoverageAmount={hasCustomCoverageAmount}
                hasCoverageAmountError={!!coverageAmountError}
                isLoadingQuotes={isLoadingQuotes}
              />
            );
          })}
        </Box>
      )}
    </div>
  );
}
