import { ReactElement, useMemo } from 'react';

import { ProductsEntity } from '@breathelife/types';

import { useSelector } from '../../../../Hooks';
import { getQuotedProducts } from '../../../../ReduxStore/Products/ProductsSelectors';
import { ApplicationDetailsWidgetView } from './ApplicationDetailsWidgetView';

type Props = {
  selectedProductId: string;
  coverageAmount?: number | null;
  isApplicationSubmitted: boolean;
  isApplicationSigned: boolean;
  onOpenSubmissionDetailsModal: () => void;
  productsEntity: ProductsEntity<string> | null;
};

export function ApplicationDetailsWidgetsContainer(props: Props): ReactElement {
  const { isLoadingProducts, isLoadingQuotes } = useSelector((store) => store.leadPlatform.products);
  const quotedProducts = useSelector(getQuotedProducts);
  const {
    selectedProductId,
    coverageAmount,
    isApplicationSubmitted,
    isApplicationSigned,
    onOpenSubmissionDetailsModal,
    productsEntity,
  } = props;

  const eligibleQuotedProducts = useMemo(() => {
    return quotedProducts.filter((product) => product.isEligible);
  }, [quotedProducts]);
  const selectedProduct = useMemo(
    () => eligibleQuotedProducts.find((product) => product.productId === selectedProductId),
    [eligibleQuotedProducts, selectedProductId],
  );

  const isLoadingWidgetData = isLoadingProducts || isLoadingQuotes;
  return (
    <ApplicationDetailsWidgetView
      selectedProduct={selectedProduct}
      coverageAmount={coverageAmount}
      isLoadingProducts={isLoadingProducts}
      isLoadingQuotes={isLoadingQuotes}
      showActionButtons={isApplicationSubmitted && isApplicationSigned}
      onOpenSubmissionDetailsModal={onOpenSubmissionDetailsModal}
      productsEntity={productsEntity}
      isLoadingWidgetData={isLoadingWidgetData}
    />
  );
}
