import _ from 'lodash';
import { CollectionInstanceIdentifiers, NodeId, VersionedAnswers, YesNoValue } from '@breathelife/types';
import { QuestionnaireEngine } from '@breathelife/questionnaire-engine';
import { NodeIds } from '@breathelife/insurance-form-builder';

type ShouldRefreshAllAnswersParams = {
  answers?: VersionedAnswers;
  currentProposedInsuredIndex: number;
  questionnaireEngine: QuestionnaireEngine;
  nodeIdsSkippingDebounce?: NodeId[];
  updatedNodeIds?: NodeId[];
};

// Checks whether the conditions are met to refresh all the answers
export function shouldRefreshAllAnswers({
  answers,
  currentProposedInsuredIndex,
  questionnaireEngine,
  nodeIdsSkippingDebounce,
  updatedNodeIds,
}: ShouldRefreshAllAnswersParams): boolean {
  if (!_.isArray(nodeIdsSkippingDebounce) || nodeIdsSkippingDebounce.length === 0 || !answers) return false;
  const updatedNodesSkippingDebounce = _.intersection(nodeIdsSkippingDebounce, updatedNodeIds);
  if (updatedNodesSkippingDebounce.length === 0) return false;
  const collectionInstanceIdentifiers: CollectionInstanceIdentifiers = {
    [NodeIds.insuredPeople]: currentProposedInsuredIndex,
  };
  return updatedNodesSkippingDebounce.some((nodeId) => {
    return questionnaireEngine.getAnswer(answers, nodeId, collectionInstanceIdentifiers) === YesNoValue.yes;
  });
}
