import { Box } from '@breathelife/mui';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { UserNotificationOptions } from '@breathelife/types';

import { SubmitButton } from '../../../../Components/Button/SubmitButton';
import { Checkbox, CheckboxGroup } from '../../../../Components/Checkbox/Checkbox';
import Typography from '../../../../Components/Typography';
import { ModalLayout } from '../../../../Layouts/Modal/ModalLayout';

type Props = {
  isOpen: boolean;
  closeModal: () => void;
  isLoading: boolean;
  preferences: UserNotificationOptions;
  onSaveClick: () => void;
  onChange: (preference: string) => void;
  onExited: () => void;
};

export function NotificationPreferencesModal(props: Props): ReactElement {
  const { isOpen, closeModal, isLoading, preferences, onSaveClick, onChange, onExited } = props;
  const { t } = useTranslation();

  return (
    <ModalLayout
      maxWidth='sm'
      isOpen={isOpen}
      closeModal={closeModal}
      onExited={onExited}
      title={t('modals.notificationPreferences.title')}
      submitButton={
        <SubmitButton onClick={onSaveClick} disabled={isLoading}>
          {t('cta.save')}
        </SubmitButton>
      }
    >
      <Box mb={1}>
        <Typography variant='body1' grey={70}>
          <CheckboxGroup label={t('modals.notificationPreferences.body')}>
            {Object.keys(preferences).map((notification) => (
              <Checkbox
                key={notification}
                color='primary'
                label={t(`modals.notificationPreferences.emails.${notification}`)}
                checked={!!preferences[notification]}
                onChange={() => {
                  onChange(notification);
                }}
              />
            ))}
          </CheckboxGroup>
        </Typography>
      </Box>
    </ModalLayout>
  );
}
