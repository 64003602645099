import { Box } from '@breathelife/mui';
import _ from 'lodash';
import { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '../../../../../Components/Icons';
import { NumberInput } from '../../../../../Components/NumberInput/NumberInput';
import Typography from '../../../../../Components/Typography';
import getCurrency from '../../../../../Helpers/currency';
import { useCarrierContext, useLocale } from '../../../../../Hooks';
import { toCurrency } from '../../../../../Localization/utils';
import { Lead } from '../../../../../Models/Lead';
import { CountryCode } from '../../../../../types';
import { NeedsAnalysisButton } from './NeedsAnalysisButton';

import styled from '../../../../../Styles/themed-styled-components';

const NeedsAnalysisContainer = styled(Box)`
  background-color: ${(props) => props.theme.colors.grey[20]};
`;

type ProductsViewHeaderProps = {
  coverageAmount?: number;
  coverageAmountError?: string;
  isCoverageAmountEditable?: boolean;
  isCoverageAmountInputEnabled?: boolean;
  isCoverageAmountInputInError: boolean;
  lead?: Lead;
  onCoverageAmountChange?: (value: number | undefined) => void;
  onSendNeedsAnalysis: () => void;
  onViewNeedsAnalysisDetails: () => void;
  recommendedCoverageAmount?: number;
  coverageAmountStep: number;
};

function displayCoverageAsCurrency(coverageAmount: number | undefined, countryCode: CountryCode): string {
  if (!coverageAmount) return '--';
  return toCurrency(Number(coverageAmount), getCurrency(countryCode), { minimumFractionDigits: 0 });
}

export function ProductsViewHeader(props: ProductsViewHeaderProps): ReactElement {
  const { t } = useTranslation();
  const locale = useLocale();
  const { countryCode } = useCarrierContext();
  const {
    coverageAmount,
    coverageAmountStep,
    isCoverageAmountEditable,
    isCoverageAmountInputEnabled,
    isCoverageAmountInputInError,
    lead,
    onCoverageAmountChange: onCoverageAmountChangeProp,
    onSendNeedsAnalysis,
    onViewNeedsAnalysisDetails,
    recommendedCoverageAmount,
  } = props;

  let readOnlyCoverageAmount;
  if (!isCoverageAmountEditable) {
    readOnlyCoverageAmount =
      !_.isUndefined(coverageAmount) && coverageAmount !== recommendedCoverageAmount
        ? coverageAmount
        : recommendedCoverageAmount;
  }

  const debouncedOnCustomCoverageChange = useMemo(() => {
    return _.debounce((selectedCoverageAmount: number | undefined) => {
      onCoverageAmountChangeProp?.(selectedCoverageAmount);
    }, 400);
  }, [onCoverageAmountChangeProp]);

  return (
    <NeedsAnalysisContainer px={4} py={3}>
      {!isCoverageAmountEditable && (
        <Box pb={1}>
          <Typography variant='h3' grey={100} component='p'>
            {t('financialNeeds')}
          </Typography>
        </Box>
      )}

      <Box display='flex' justifyContent='space-between' alignItems='flex-end'>
        {!isCoverageAmountEditable && (
          <Box>
            <Typography variant='small1' grey={70} component='p'>
              {t('coverageAmount')}
            </Typography>
            <Typography variant='body1' grey={90} component='p'>
              {displayCoverageAsCurrency(readOnlyCoverageAmount, countryCode)}
            </Typography>
          </Box>
        )}

        {isCoverageAmountEditable && (
          <Box>
            <Typography variant='body1' grey={90}>
              {displayCoverageAsCurrency(recommendedCoverageAmount, countryCode)}
            </Typography>
            <Box ml={1} display='inline'>
              <Typography variant='small1' grey={70}>
                {t('recommendedCoverageAmount')}
              </Typography>
            </Box>
            <Box mt={1} maxWidth={'170px'} justifyContent='center' display='flex'>
              <NumberInput
                id='needsAnalysisCoverageBox-coverageAmount'
                label={t('inputs.coverageAmount')}
                inputVariant='outlined'
                isCurrency
                locale={locale}
                transparent={false}
                value={coverageAmount}
                onNumberChange={debouncedOnCustomCoverageChange}
                disabled={!isCoverageAmountInputEnabled}
                error={isCoverageAmountInputInError}
                inputTooltip={{
                  icon: <Icon name='info' variant='primary' width='15' height='15' />,
                  text:
                    t('product.roundedAmount', {
                      amount: toCurrency(coverageAmountStep, getCurrency(countryCode), {
                        minimumFractionDigits: 0,
                      }),
                    }) ?? '',
                }}
                decimalScale={0}
              />
            </Box>
          </Box>
        )}

        <Box>
          <NeedsAnalysisButton
            lead={lead}
            onViewNeedsAnalysisDetails={onViewNeedsAnalysisDetails}
            onSendNeedsAnalysis={onSendNeedsAnalysis}
          />
        </Box>
      </Box>
    </NeedsAnalysisContainer>
  );
}
