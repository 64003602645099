export const DEFAULT_COVERAGE_AMOUNT_STEP = 1;

export type LegacyProductInfo = {
  id: string;
  productId: string;
  name: string;
  category: string;
  type: string;
  includedFeatures: string[];
  excludedFeatures: string[];
  featured: boolean;
  carrierId: string;
  carrierName: string;
  isEligible: boolean;

  shortName?: string;
  highlights?: Highlight[];
  details?: ProductDetails;
  coverageRange?: {
    min: number;
    max: number;
  };
};

export type QuotedProduct = LegacyProductInfo & { premium?: number | null };

type Subsection = {
  title: string;
  text: string;
};

type Highlight = {
  title: string;
};

type Option = {
  title: string;
};

type RepeatableSection = {
  title: string;
  subsections: Subsection[];
};

type ProductDetails = {
  repeatableSections: RepeatableSection[];
  options: Option[];
};
