import { Questionnaire, CreateQuestionnaireData } from '@breathelife/types';
import ApiService from './ApiService';

export async function fetchAllQuestionnaires(): Promise<Questionnaire[]> {
  const response = await ApiService.admin.fetchAllQuestionnaires();
  return response.data;
}

export async function createQuestionnaire(data: CreateQuestionnaireData): Promise<Questionnaire> {
  const response = await ApiService.admin.createQuestionnaire(data);
  return response.data;
}

export async function getQuestionnaireByVersionIdForProUser(questionnaireVersionId: string): Promise<Questionnaire> {
  const response = await ApiService.getUserQuestionnaires({
    questionnaireVersionId,
  });
  if (response.data.length === 1) {
    return response.data[0];
  }
  throw new Error(`Did not find questionnaire for questionnaire version with id ${questionnaireVersionId}`);
}
