import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

import { TypewriterTracking } from '@breathelife/frontend-tracking';

import { useDispatch } from '../../Hooks';
import { Lead } from '../../Models/Lead';
import { QueryId } from '../../ReactQuery/common/common.types';
import { notificationSlice } from '../../ReduxStore/Notification/NotificationSlice';
import {
  fetchLeads,
  getLead,
  FetchLeadsOptions,
  LeadsMappedGetResponse,
  validateLeadEmail,
  ValidateLeadEmailResponse,
} from '../../Services/LeadsService';

export function useFindLeadsQuery(
  leadsOptions?: FetchLeadsOptions,
  options?: UseQueryOptions<LeadsMappedGetResponse>,
): UseQueryResult<LeadsMappedGetResponse> {
  const dispatch = useDispatch();
  return useQuery<LeadsMappedGetResponse>(
    [QueryId.leads, leadsOptions],
    async () => {
      if (!leadsOptions) return Promise.reject(new Error('Lead options are missing.'));
      return await fetchLeads(leadsOptions);
    },
    {
      ...options,
      enabled: !!leadsOptions,
      onSuccess: (data) => {
        options?.onSuccess?.(data);

        if (leadsOptions?.queryParams?.$search) {
          TypewriterTracking.searchedForAnApplication({ hasSearchResults: !!data.total });
        }
      },
      onError: (error) => {
        options?.onError?.(error);

        if (error instanceof Error) {
          dispatch(notificationSlice.actions.setError({ message: error.message }));
        }
      },
    },
  );
}

export function useGetLeadQuery(leadId?: number, options?: UseQueryOptions<Lead>): UseQueryResult<Lead> {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  return useQuery<Lead>(
    [QueryId.lead, leadId],
    async () => {
      if (!leadId) return Promise.reject(new Error('Lead id is missing.'));
      return await getLead(leadId);
    },
    {
      ...options,
      enabled: !!leadId,
      onError: (error) => {
        options?.onError?.(error);

        if (axios.isAxiosError(error) && error.response?.status === 403) {
          dispatch(notificationSlice.actions.setError({ message: t('notifications.forbiddenGetLead') }));
        } else {
          dispatch(notificationSlice.actions.setError({ message: t('notifications.failedToGetLead') }));
        }
      },
    },
  );
}

export function useValidateLeadEmailQuery(
  leadId: number,
  options?: UseQueryOptions<ValidateLeadEmailResponse>,
): UseQueryResult<ValidateLeadEmailResponse> {
  return useQuery<ValidateLeadEmailResponse>(
    [QueryId.leadEmailValid, leadId],
    async () => await validateLeadEmail(leadId),
    options,
  );
}
