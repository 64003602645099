import _ from 'lodash';
import { ReactElement, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../Styles/themed-styled-components';

import { Skeleton, Box, Grid } from '@breathelife/mui';
import { ApplicationMode, Permission, QuotedInsuranceProduct } from '@breathelife/types';
import { Loader, Tag } from '@breathelife/ui-components';

import { ApplicationModeBadge } from '../../Components/Badge/ApplicationModeBadge';
import { OutcomeBadge } from '../../Components/Badge/OutcomeBadge';
import { Button } from '../../Components/Button/Button';
import { Checkbox } from '../../Components/Checkbox/Checkbox';
import { Progress } from '../../Components/Progress/Progress';
import { RestrictedToUserPermissions } from '../Restricted/RestrictedToUserPermissions';
import Typography from '../../Components/Typography';
import getCurrency from '../../Helpers/currency';
import { canEditApplication, canOnlyReadApplication, isAssignedToUser } from '../../Helpers/permissions';
import { useApplicationProgress, useCarrierContext } from '../../Hooks';
import { toCurrency } from '../../Localization/utils';
import { Application } from '../../Models/Application';
import { Lead } from '../../Models/Lead';
import { QuotedProduct } from '../../Models/Product';
import { useGetPointOfSaleDecisionsOutcomeForSingleInsured } from '../../ReactQuery/PointOfSaleDecisions/pointOfSaleDecisions.queries';
import { useFetchCarrierQuestionnaireQuery } from '../../ReactQuery/CarrierQuestionnaires/carrierQuestionnaire.queries';
import { TypographyVariant } from '../../Styles/Types';
import { LeadItemButtonContainer, LeadItemContainer } from './Styles';
import { useApplicationQuestionnaireAndLineOfBusiness } from '../../Hooks/Application/useApplicationQuestionnaireAndLineOfBusiness';

export type LeadItemProps = {
  application?: Application;
  hasCoverageAmountError?: boolean;
  hasCustomCoverageAmount?: boolean;
  isItemSelected: boolean;
  isLoadingQuotes?: boolean;
  lead?: Lead;
  onLaunchApplication: (application: Application | undefined, canOnlyReadApplication: boolean) => void;
  onToggleIsDeclarationSent?: (applicationId: string, isDeclarationSent: boolean) => void;
  product?: QuotedProduct | QuotedInsuranceProduct;
  title: string | undefined;
  titleVariant?: TypographyVariant;
};

export function LeadItem(props: LeadItemProps): ReactElement {
  const { t } = useTranslation();
  const theme = useTheme();
  const { countryCode, features } = useCarrierContext();
  const {
    application,
    hasCoverageAmountError,
    hasCustomCoverageAmount,
    isItemSelected,
    isLoadingQuotes,
    lead,
    onLaunchApplication,
    onToggleIsDeclarationSent,
    product,
    title,
    titleVariant,
  } = props;
  const { data: questionnaireData, isLoading: isLoadingQuestionnaire } = useFetchCarrierQuestionnaireQuery(
    application?.id,
    {},
  );

  const shouldFetchPointOfSaleDecisionOutcome =
    !!features.pointOfSaleDecisionOutcome?.enabled && !!features.questionnaireScreen?.enabled && !!application?.id;

  const { data: pointOfSaleDecisionOutcomeForFirstInsured } = useGetPointOfSaleDecisionsOutcomeForSingleInsured(
    application?.id,
    {
      staleTime: 0, //Always refetch.
      enabled: shouldFetchPointOfSaleDecisionOutcome,
    },
  );

  const { lineOfBusinessName } = useApplicationQuestionnaireAndLineOfBusiness(application);

  const isProduct = typeof product !== 'undefined';
  const leadStatus = lead?.status || 'new';

  const applicationProgress = useApplicationProgress({ application, questionnaireResponse: questionnaireData });

  const showProgress = application && !_.isUndefined(applicationProgress) && isItemSelected;

  const isLeadAssignedToUser = isAssignedToUser(lead);
  const canUserEditApplication = canEditApplication(lead, hasCoverageAmountError);
  const canUserOnlyReadApplication = canOnlyReadApplication(lead, hasCoverageAmountError);

  const onToggleIsDeclarationSentClick = useCallback(() => {
    if (!application || !onToggleIsDeclarationSent) return;
    onToggleIsDeclarationSent(application.id, !application.isDeclarationSent);
  }, [application, onToggleIsDeclarationSent]);

  const onLaunchApplicationClick = useCallback(() => {
    onLaunchApplication(application, canUserOnlyReadApplication);
  }, [application, onLaunchApplication, canUserOnlyReadApplication]);

  return (
    <LeadItemContainer>
      <Box>
        <Typography variant={titleVariant || 'h3'} grey={100}>
          {title}
          {application && application.mode === ApplicationMode.paper && (
            <Box display='inline-block' ml='10px'>
              <ApplicationModeBadge mode={application.mode} />
            </Box>
          )}
        </Typography>
        {showProgress && (
          <Box py={1}>
            {isLoadingQuestionnaire ? (
              <Skeleton width={120} height='2em' />
            ) : (
              <Progress
                applicationId={application?.id}
                checkPaymentInfo={features?.payments?.enabled ?? false}
                progress={applicationProgress || 0}
                status={leadStatus}
              />
            )}
          </Box>
        )}
        {lineOfBusinessName && (
          <Grid container spacing={1}>
            {lineOfBusinessName && (
              <Grid item>
                <Tag textColor={theme.colors.orange[60]} label={lineOfBusinessName} />
              </Grid>
            )}
          </Grid>
        )}

        {shouldFetchPointOfSaleDecisionOutcome && pointOfSaleDecisionOutcomeForFirstInsured && isItemSelected && (
          <Box mt={1}>
            <OutcomeBadge outcome={pointOfSaleDecisionOutcomeForFirstInsured} />
          </Box>
        )}
        {isProduct && isLeadAssignedToUser && application && (
          <RestrictedToUserPermissions requiredPermissions={[Permission.ApplicationSendDeclaration]}>
            <Checkbox
              checked={application.isDeclarationSent}
              onChange={onToggleIsDeclarationSentClick}
              label={t('leadDetailDrawer.isDeclarationSent')}
            />
          </RestrictedToUserPermissions>
        )}
      </Box>
      <Box>
        {isProduct && isLoadingQuotes && <Loader size='20px' />}
        {isProduct && !isLoadingQuotes && (
          <Typography
            color={hasCustomCoverageAmount && product?.premium ? theme.colors.orange[50] : undefined}
            variant='h2'
            grey={90}
            component='p'
            align='right'
          >
            {product?.premium ? toCurrency(product?.premium, getCurrency(countryCode)) : '--'}
            {product?.premium && (
              <Typography variant='body5' grey={90}>
                &nbsp;
                {t('perMonth')}
              </Typography>
            )}
          </Typography>
        )}
        <LeadItemButtonContainer>
          <Box my={1}>
            <Button
              color='primary'
              variant={isItemSelected ? 'outlined' : 'contained'}
              disabled={
                (!canUserEditApplication && !canUserOnlyReadApplication) ||
                (isProduct && (!product?.premium || isLoadingQuotes))
              }
              onClick={onLaunchApplicationClick}
            >
              {isItemSelected ? t('cta.continue') : t('cta.launchApplication')}
            </Button>
          </Box>
        </LeadItemButtonContainer>
      </Box>
    </LeadItemContainer>
  );
}
