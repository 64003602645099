import { useTranslation } from 'react-i18next';
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

import { QuoteInfo, TotalPremiums } from '@breathelife/types';

import { useDispatch } from '../../Hooks';
import { QueryId } from '../../ReactQuery/common/common.types';
import { notificationSlice } from '../../ReduxStore/Notification/NotificationSlice';
import { getProductsWidgetTotalPremiums } from '../../Services/AssistedApplicationsService';
import { getQuotes } from '../../Services/QuotesService';

export function useGetProductsWidgetTotalPremiumsQuery(
  applicationId?: string,
  options?: UseQueryOptions<TotalPremiums | null>,
): UseQueryResult<TotalPremiums | null> {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  return useQuery<TotalPremiums | null>(
    [QueryId.productsWidgetTotalPremiums, applicationId],
    async () => {
      if (!applicationId) {
        return Promise.reject(new Error('Application id is missing.'));
      }
      return await getProductsWidgetTotalPremiums(applicationId);
    },
    {
      ...options,
      enabled: !!applicationId,
      onError: () => {
        dispatch(
          notificationSlice.actions.setError({
            message: t('notifications.getProductsWidgetTotalPremiumsFailure'),
          }),
        );
      },
    },
  );
}

// Not used yet
export function useFetchQuotesQuery(
  applicationId?: string,
  coverageAmount?: number | null,
  options?: UseQueryOptions<QuoteInfo>,
): UseQueryResult<QuoteInfo> {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  return useQuery<QuoteInfo>(
    [QueryId.quotes, applicationId],
    async () => {
      if (!applicationId) {
        return Promise.reject(new Error('Application id is missing.'));
      }
      return await getQuotes(applicationId, coverageAmount);
    },
    {
      ...options,
      enabled: !!applicationId,
      onError: () => {
        dispatch(
          notificationSlice.actions.setError({
            message: t('notifications.failedToFetchQuotes'),
          }),
        );
      },
    },
  );
}
