import { ReactElement, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { useDispatch, useNavigation, useSelector } from '../../../Hooks';
import { Application } from '../../../Models/Application';
import { DetailViewType } from '../../../Models/Layout';
import { Lead } from '../../../Models/Lead';
import { closeRightPanel } from '../../../ReduxStore/Layout/LayoutOperations';
import { resetProducts } from '../../../ReduxStore/Products/ProductsOperations';
import { LeadDetailView } from './LeadDetailView';
import { generateLeadsListUrl } from '../../../Navigation/Urls';

type LeadDetailContainerProps = {
  onSendEmailClick: (lead: Lead) => void;
  onToggleArchiveClick: (lead: Lead, userId: string) => void;
  onCopyClick: (application: Application) => void;
  onDeleteClick: (lead: Lead) => void;
  onDownloadFilesClick: (lead: Lead) => void;
};

function LeadDetailContainer(props: LeadDetailContainerProps): ReactElement | null {
  const { onToggleArchiveClick: onToggleArchiveClickProp } = props;
  const dispatch = useDispatch();
  const { leadTab: navigationLeadTab } = useNavigation();
  const navigate = useNavigate();
  const { user } = useSelector((store) => store.leadPlatform.authentication);
  const { isOpen, type } = useSelector((store) => store.leadPlatform.layout.rightPanelState);

  const onClose = useCallback(() => {
    dispatch(closeRightPanel());
    navigate(generateLeadsListUrl(navigationLeadTab));
    dispatch(resetProducts());
  }, [dispatch, navigationLeadTab, navigate]);

  const onToggleArchiveClick = useCallback(
    (lead: Lead) => {
      if (!user) return;
      onToggleArchiveClickProp(lead, user.auth0Id);
    },
    [onToggleArchiveClickProp, user],
  );

  return (
    <LeadDetailView
      {...props}
      isOpen={isOpen}
      onClose={onClose}
      onToggleArchiveClick={onToggleArchiveClick}
      viewType={type ?? DetailViewType.read}
    />
  );
}

export default LeadDetailContainer;
