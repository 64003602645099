import {
  UserNotificationPreference,
  UserNotificationOptions,
  NotificationTypes,
  NotificationEnabled,
} from '@breathelife/types';

export function getNotificationTypesFromUserPreference(options?: {
  defaultValue?: NotificationEnabled;
  preference?: UserNotificationPreference;
}): UserNotificationOptions {
  const { defaultValue, preference } = options || {};

  const defaults: UserNotificationOptions = {
    [NotificationTypes.leadScheduledACallEmail]: defaultValue ?? null,
    [NotificationTypes.leadCompletedNeedsAnalysisEmail]: defaultValue ?? null,
  };

  return Object.values(NotificationTypes).reduce<UserNotificationOptions>(
    (result: UserNotificationOptions, notificationType: NotificationTypes) => {
      const preferenceValue = preference && preference[notificationType];
      if (preferenceValue !== undefined) {
        result[notificationType] = preferenceValue;
      }

      return result;
    },
    defaults,
  );
}
