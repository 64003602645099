import i18next from 'i18next';

import { productsSlice } from '../../ReduxStore/Products/ProductsSlice';
import { Dispatch } from '../../ReduxStore/types';
import { getQuotes } from '../../Services/QuotesService';
import { notificationSlice } from '../Notification/NotificationSlice';

export const fetchQuotes =
  (applicationId: string, coverageAmount?: number | null) =>
  async (dispatch: Dispatch): Promise<void> => {
    if (!applicationId) return;
    dispatch(productsSlice.actions.setIsLoadingQuotes(true));
    try {
      const quotes = await getQuotes(applicationId, coverageAmount);
      dispatch(productsSlice.actions.setQuotes(quotes));
    } catch (error: any) {
      dispatch(
        notificationSlice.actions.setError({
          message: i18next.t('notifications.failedToFetchQuotes'),
        }),
      );
    } finally {
      dispatch(productsSlice.actions.setIsLoadingQuotes(false));
    }
  };
