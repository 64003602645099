import { Application } from '../Models/Application';
import ApiService from './ApiService';

type SubmitNeedsAnalysisData = {
  applicationId: string;
  productId: string;
  coverageAmount: number;
  premium: number;
};

export type SubmitNeedsAnalysisResponse = { application: Application; redirectUrl: string };

export async function submitNeedsAnalysis(data: SubmitNeedsAnalysisData): Promise<SubmitNeedsAnalysisResponse> {
  const response = await ApiService.submitNeedsAnalysis(
    data.applicationId,
    data.coverageAmount,
    data.productId,
    data.premium,
  );
  return response.data;
}
