import dayjs from 'dayjs';
import { Dispatch, ChangeEvent, ReactElement, SetStateAction, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import {
  HoldingForm,
  InsuranceFirm,
  Language,
  LineOfBusinessName,
  ProductType,
  SupportedBuyer,
} from '@breathelife/types';
import { AutoCompleteMultiple, Input as TextInput } from '@breathelife/ui-components';
import { AddIcon, Box, Grid } from '@breathelife/mui';

import { Select } from '../../../../Components/Select/Select';
import { ActionButton } from '../../../../Components/Button/ActionButton';
import { Checkbox, CheckboxGroup } from '../../../../Components/Checkbox/Checkbox';
import { NumberInput } from '../../../../Components/NumberInput/NumberInput';
import Typography from '../../../../Components/Typography';
import {
  getFieldValidationError,
  getProductIdUniqueValidationError,
} from '../../../../Helpers/inputValidation/form/product';
import { getOptionsFromEnum } from '../../../../Helpers/options';
import { useDispatch, useSelector } from '../../../../Hooks';
import { getCurrentLocale } from '../../../../Localization/utils';
import { ModalType } from '../../../../Models/Layout';
import {
  getSelectedProduct,
  getSelectedProductPricing,
} from '../../../../ReduxStore/Admin/ProductManagement/ProductManagementSelectors';
import { layoutSlice } from '../../../../ReduxStore/Layout/LayoutSlice';
import { getLinkableProductsForTheSelectedProduct } from '../../../../ReduxStore/Products/ProductsSelectors';
import { DeleteItemFunction, ProductFormFields, ValueChangeFunction } from '../types';
import { AdditionalFeatures } from './Components/AdditionalFeatures';
import { CoverageAmountRanges } from './Components/CoverageAmountRanges';
import { EntitySelector } from './Components/EntitySelector';
import { StyledActionButton, StyledGrid } from './Styles';

type Props = {
  productFormState: ProductFormFields;
  firms: Pick<InsuranceFirm, 'id' | 'name'>[];
  enabledLanguages: Language[];
  onInputValueChange: ValueChangeFunction;
  onListFieldItemDelete: DeleteItemFunction;
  validationErrors: Partial<Record<keyof ProductFormFields, any>>;
  setValidationErrors: Dispatch<SetStateAction<Partial<Record<keyof ProductFormFields, any>>>>;
  productUUID: string;
};

export function ProductForm({
  onInputValueChange,
  validationErrors,
  setValidationErrors,
  productUUID,
  ...props
}: Props): ReactElement {
  const locale = getCurrentLocale();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const selectedProduct = useSelector(getSelectedProduct);
  const selectedProductPricing = useSelector(getSelectedProductPricing)?.[0];
  const { availableParents, availableRiders } = useSelector(getLinkableProductsForTheSelectedProduct(locale));

  const {
    name,
    productId,
    lineOfBusiness,
    insuranceFirmId,
    type,
    holdingForm,
    supportedBuyers = [],
    minimumAge,
    maximumAge,
    policyDetailsUrl,
    temporaryInsuranceUrl,
    renewable,
    maxRenewalAge,
    convertible,
    maxConversionAge,
    requiresMedicalExam,
    additionalFeatures = [],
    coverageAmountRanges = [],
    isRider,
    linkedProducts,
    linkedAddons,
    selector,
    archived = false,
    index,
  } = props.productFormState;

  const lineOfBusinessOptions = getOptionsFromEnum<LineOfBusinessName>(
    LineOfBusinessName,
    'admin.productManagement.options.lineOfBusiness',
  );

  const productTypeOptions = getOptionsFromEnum<ProductType>(ProductType, 'admin.productManagement.options.type');

  const holdingFormOptions = getOptionsFromEnum<HoldingForm>(
    HoldingForm,
    'admin.productManagement.options.holdingForm',
  );

  const supportedBuyersOptions = getOptionsFromEnum<SupportedBuyer>(
    SupportedBuyer,
    'admin.productManagement.options.supportedBuyers',
  );

  const insuranceFirmOptions = props.firms.map((firm) => ({ label: firm.name?.[locale] ?? '', value: firm.id }));

  const onCreateFirmModalOpen = useCallback(() => {
    void dispatch(layoutSlice.actions.setModalState({ modalState: { isOpen: true, type: ModalType.createFirm } }));
  }, [dispatch]);

  const onModifyProductPricing = useCallback(() => {
    void dispatch(layoutSlice.actions.setModalState({ modalState: { isOpen: true, type: ModalType.productPricing } }));
  }, [dispatch]);

  const onChangeProductId = useCallback(
    async (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const newProductId = event.target.value;
      onInputValueChange({
        path: 'productId',
        value: newProductId,
      });

      let validationError = getFieldValidationError('productId', newProductId);
      if (!validationError) {
        validationError = await getProductIdUniqueValidationError(newProductId, productUUID);
      }
      setValidationErrors((prev) => ({
        ...prev,
        productId: validationError,
      }));
    },
    [onInputValueChange, setValidationErrors, productUUID],
  );

  const readonly = archived;

  return (
    <Grid container spacing={2}>
      {props.enabledLanguages.map((language) => (
        <Grid item xs={12} key={`productManagement-name-${language}`}>
          <TextInput
            name={`productManagement-name-${language}`}
            inputVariant='outlined'
            label={`${t('admin.productManagement.labels.nameAndLanguage', {
              language: t(`language.${language}`),
            })}`}
            value={name?.[language] ?? ''}
            error={Boolean(validationErrors?.name?.[language])}
            validationError={validationErrors?.name?.[language]}
            required
            disabled={readonly}
            onChange={(event) => {
              const value = event.target.value;
              onInputValueChange({
                path: `name.${language}`,
                value,
              });
              setValidationErrors((prev) => ({
                ...prev,
                name: { ...validationErrors.name, [language]: getFieldValidationError('name', value) },
              }));
            }}
          />
        </Grid>
      ))}
      <Grid item xs={6}>
        <Select
          id='firm-select'
          label={t('admin.productManagement.labels.insuranceFirmId')}
          value={insuranceFirmId ?? ''}
          options={insuranceFirmOptions}
          disabled={readonly}
          onChange={(value) => onInputValueChange({ path: 'insuranceFirmId', value })}
          required
        />
      </Grid>
      <Grid item xs={6}>
        <Box mt={3}>
          <ActionButton
            data-testid='addNewProductButton'
            color='primary'
            disabled={readonly}
            variant='contained'
            onClick={onCreateFirmModalOpen}
            startIcon={<AddIcon htmlColor='white' />}
          >
            {t('cta.addNew')}
          </ActionButton>
        </Box>
      </Grid>
      <Grid item xs={6}>
        <TextInput
          required
          name='productManagement-productId'
          inputVariant='outlined'
          disabled={readonly}
          label={t('admin.productManagement.labels.productId')}
          value={productId ?? ''}
          error={Boolean(validationErrors.productId)}
          validationError={validationErrors.productId}
          onChange={onChangeProductId}
        />
      </Grid>
      <StyledGrid item xs={6}>
        <CheckboxGroup label={t('admin.productManagement.labels.supportedBuyers')}>
          <Box display='flex'>
            {supportedBuyersOptions.map((option) => {
              const isOptionSelected = supportedBuyers.includes(option.value);
              return (
                <Checkbox
                  key={option.value}
                  color='primary'
                  label={option.label}
                  disabled={readonly}
                  checked={isOptionSelected}
                  onChange={() =>
                    onInputValueChange({
                      path: 'supportedBuyers',
                      value: isOptionSelected
                        ? supportedBuyers.filter((value) => value !== option.value)
                        : [...supportedBuyers, option.value],
                    })
                  }
                />
              );
            })}
          </Box>
        </CheckboxGroup>
      </StyledGrid>
      <Grid item xs={6}>
        <Select
          id='line-of-business-select'
          label={t('admin.productManagement.labels.lineOfBusiness')}
          value={lineOfBusiness ?? ''}
          disabled={readonly}
          options={lineOfBusinessOptions}
          onChange={(value) => onInputValueChange({ path: 'lineOfBusiness', value: value as LineOfBusinessName })}
          required
        />
      </Grid>
      {lineOfBusiness === LineOfBusinessName.life && (
        <Grid item xs={6}>
          <Select
            id='product-type-select'
            label={t('admin.productManagement.labels.type')}
            value={type ?? ''}
            disabled={readonly}
            options={productTypeOptions}
            onChange={(value) => onInputValueChange({ path: 'type', value: value as ProductType })}
            required
          />
        </Grid>
      )}
      <Grid item xs={6}>
        <Select
          id='holding-form-select'
          label={t('admin.productManagement.labels.holdingForm')}
          value={holdingForm ?? ''}
          options={holdingFormOptions}
          disabled={readonly}
          required
          onChange={(value) => onInputValueChange({ path: 'holdingForm', value: value as HoldingForm })}
        />
      </Grid>
      <Grid item xs={6}>
        <NumberInput
          id='productManagement-minimumAge'
          inputVariant='outlined'
          label={t('admin.productManagement.labels.minimumAge')}
          required
          disabled={readonly}
          onNumberChange={(value) => {
            onInputValueChange({
              path: 'minimumAge',
              value,
            });
            setValidationErrors((prev) => {
              return {
                ...prev,
                minimumAge: getFieldValidationError('minimumAge', value),
              };
            });
          }}
          value={minimumAge ?? ''}
          error={Boolean(validationErrors.minimumAge)}
          validationError={validationErrors.minimumAge}
        />
      </Grid>
      <Grid item xs={6}>
        <NumberInput
          id='productManagement-maximumAge'
          inputVariant='outlined'
          label={t('admin.productManagement.labels.maximumAge')}
          disabled={readonly}
          onNumberChange={(value) => {
            onInputValueChange({
              path: 'maximumAge',
              value,
            });
            setValidationErrors((prev) => ({
              ...prev,
              maximumAge: getFieldValidationError('maximumAge', value),
            }));
          }}
          required
          value={maximumAge ?? ''}
          error={Boolean(validationErrors.maximumAge)}
          validationError={validationErrors.maximumAge}
        />
      </Grid>
      <Grid item xs={6}>
        <NumberInput
          id='productManagement-index'
          inputVariant='outlined'
          disabled={readonly}
          label={t('admin.productManagement.labels.index')}
          onNumberChange={(value) => {
            onInputValueChange({
              path: 'index',
              value,
            });
          }}
          value={index ?? ''}
        />
      </Grid>
      <Grid item xs={6}>
        <TextInput
          name='productManagement-policyDetailsUrl'
          inputVariant='outlined'
          label={t('admin.productManagement.labels.policyDetailsUrl')}
          value={policyDetailsUrl ?? ''}
          disabled={readonly}
          error={Boolean(validationErrors.policyDetailsUrl)}
          validationError={validationErrors.policyDetailsUrl}
          onChange={(event) => {
            const value = event.target.value;
            onInputValueChange({
              path: 'policyDetailsUrl',
              value,
            });
            setValidationErrors((prev) => ({
              ...prev,
              policyDetailsUrl: getFieldValidationError('policyDetailsUrl', value),
            }));
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <TextInput
          name='productManagement-temporaryInsuranceUrl'
          inputVariant='outlined'
          label={t('admin.productManagement.labels.temporaryInsuranceUrl')}
          value={temporaryInsuranceUrl ?? ''}
          disabled={readonly}
          error={Boolean(validationErrors.temporaryInsuranceUrl)}
          validationError={validationErrors.temporaryInsuranceUrl}
          onChange={(event) => {
            const value = event.target.value;
            onInputValueChange({
              path: 'temporaryInsuranceUrl',
              value,
            });
            setValidationErrors((prev) => ({
              ...prev,
              temporaryInsuranceUrl: getFieldValidationError('temporaryInsuranceUrl', value),
            }));
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <Checkbox
          color='primary'
          label={t('admin.productManagement.labels.renewable')}
          checked={renewable}
          disabled={readonly}
          onChange={() =>
            onInputValueChange({
              path: 'renewable',
              value: !renewable,
            })
          }
        />
        {renewable && (
          <Grid item xs={6}>
            <NumberInput
              id='productManagement-maxRenewalAge'
              inputVariant='outlined'
              disabled={readonly}
              label={t('admin.productManagement.labels.maxRenewalAge')}
              onNumberChange={(value) => {
                onInputValueChange({
                  path: 'maxRenewalAge',
                  value,
                });
                setValidationErrors((prev) => ({
                  ...prev,
                  maxRenewalAge: getFieldValidationError('maxRenewalAge', value),
                }));
              }}
              value={maxRenewalAge ?? ''}
              error={Boolean(validationErrors.maxRenewalAge)}
              validationError={validationErrors.maxRenewalAge}
            />
          </Grid>
        )}
      </Grid>
      <Grid item xs={12}>
        <Checkbox
          color='primary'
          disabled={readonly}
          label={t('admin.productManagement.labels.addonOrRider')}
          checked={isRider}
          onChange={() =>
            onInputValueChange({
              path: 'isRider',
              value: !isRider,
            })
          }
        />
      </Grid>
      <Grid item xs={12}>
        {isRider ? (
          <AutoCompleteMultiple
            label={t('admin.productManagement.labels.linkToTheseProducts')}
            disabled={readonly}
            noOptionMessage={t('admin.productManagement.labels.noProductAvailable')}
            optionInfo={{
              toOption: (item) => ({
                label: item.name[locale] || t('admin.productManagement.labels.missingProductName'),
                value: item.id,
              }),
              selectedItems: linkedProducts || [],
              items: availableParents,
              onChange: (newLinkedProducts: ProductFormFields['linkedProducts']) => {
                onInputValueChange({ path: 'linkedProducts', value: newLinkedProducts });
              },
            }}
          />
        ) : (
          <AutoCompleteMultiple
            label={t('admin.productManagement.labels.linkToTheseAddons')}
            disabled={readonly}
            noOptionMessage={t('admin.productManagement.labels.noAddonAvailable')}
            optionInfo={{
              toOption: (item) => ({
                label: item.name[locale] || t('admin.productManagement.labels.missingProductName'),
                value: item.id,
              }),
              selectedItems: linkedAddons || [],
              items: availableRiders,
              onChange: (newLinkedAddons: ProductFormFields['linkedAddons']) => {
                onInputValueChange({
                  path: 'linkedAddons',
                  value: newLinkedAddons,
                });
              },
            }}
          />
        )}
      </Grid>

      <Grid item xs={12}>
        <Checkbox
          color='primary'
          disabled={readonly}
          label={t('admin.productManagement.labels.convertible')}
          checked={convertible}
          onChange={() =>
            onInputValueChange({
              path: 'convertible',
              value: !convertible,
            })
          }
        />

        {convertible && (
          <Grid item xs={6}>
            <NumberInput
              id='productManagement-maxConversionAge'
              inputVariant='outlined'
              disabled={readonly}
              label={t('admin.productManagement.labels.maxConversionAge')}
              onNumberChange={(value) => {
                onInputValueChange({
                  path: 'maxConversionAge',
                  value,
                });
                setValidationErrors((prev) => ({
                  ...prev,
                  maxConversionAge: getFieldValidationError('maxConversionAge', value),
                }));
              }}
              value={maxConversionAge ?? ''}
              error={Boolean(validationErrors.maxConversionAge)}
              validationError={validationErrors.maxConversionAge}
            />
          </Grid>
        )}
      </Grid>
      <Grid item xs={12}>
        <Checkbox
          color='primary'
          label={t('admin.productManagement.labels.requiresMedicalExam')}
          disabled={readonly}
          checked={requiresMedicalExam}
          onChange={() =>
            onInputValueChange({
              path: 'requiresMedicalExam',
              value: !requiresMedicalExam,
            })
          }
        />
      </Grid>

      <Grid item xs={12}>
        <EntitySelector onInputValueChange={onInputValueChange} selector={selector} disabled={readonly} />
      </Grid>

      <Grid item xs={12}>
        <AdditionalFeatures
          additionalFeatures={additionalFeatures}
          onInputValueChange={onInputValueChange}
          enabledLanguages={props.enabledLanguages}
          disabled={readonly}
          onListFieldItemDelete={props.onListFieldItemDelete}
        />
      </Grid>
      <Grid item xs={12}>
        <CoverageAmountRanges
          disabled={readonly}
          coverageAmountRanges={coverageAmountRanges}
          onInputValueChange={onInputValueChange}
          onListFieldItemDelete={props.onListFieldItemDelete}
        />
      </Grid>
      <Grid item xs={12}>
        <Box width='100%'>
          <Typography variant='h3'>{t('admin.productManagement.labels.pricing')}</Typography>
        </Box>
        <Box mt={3} mr={1} display='grid' alignItems='center' gap='8px' gridTemplateColumns='1fr auto'>
          {selectedProductPricing?.createdAt ? (
            <Typography variant='body3'>
              {t('admin.productManagement.addedAt', { date: dayjs(selectedProductPricing.createdAt).format('LLL') })}
            </Typography>
          ) : null}
          <StyledActionButton
            disabled={!selectedProduct || readonly}
            data-testid='modifyProductPricing'
            color='primary'
            variant='contained'
            onClick={onModifyProductPricing}
            startIcon={<AddIcon htmlColor='white' />}
          >
            {selectedProductPricing ? t('admin.productManagement.modifyPricing') : t('cta.addNew')}
          </StyledActionButton>
        </Box>
      </Grid>
    </Grid>
  );
}
