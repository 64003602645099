import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { PaymentFrequency, QuoteInfo, LocalizedInsuranceProduct } from '@breathelife/types';

import { LegacyProductInfo } from '../../Models/Product';

export type ProductsState = {
  applicationId: string | undefined;
  products: LegacyProductInfo[] | LocalizedInsuranceProduct[];
  quotes: QuoteInfo;
  isLoadingProducts: boolean;
  isLoadingQuotes: boolean;
};

type SetApplicationIdPayload = string;
type ProductsPayload = LegacyProductInfo[] | LocalizedInsuranceProduct[];
type QuotesPayload = QuoteInfo;
type SetIsLoadingPayload = boolean;

type ActionsType = {
  setApplicationId: (state: ProductsState, { payload }: PayloadAction<SetApplicationIdPayload>) => void;
  setProducts: (state: ProductsState, { payload }: PayloadAction<ProductsPayload>) => void;
  setQuotes: (state: ProductsState, { payload }: PayloadAction<QuotesPayload>) => void;
  setIsLoadingProducts: (state: ProductsState, { payload }: PayloadAction<SetIsLoadingPayload>) => void;
  setIsLoadingQuotes: (state: ProductsState, { payload }: PayloadAction<SetIsLoadingPayload>) => void;
  resetQuotes: (state: ProductsState) => void;
  reset: (state: ProductsState) => void;
};

const defaultState = {
  applicationId: undefined,
  products: [],
  quotes: {
    quotePerProduct: {},
    paymentFrequency: PaymentFrequency.monthly,
  },
  isLoadingProducts: false,
  isLoadingQuotes: false,
};

export const productsSlice = createSlice<ProductsState, ActionsType>({
  name: 'leadPlatform/products',
  initialState: defaultState,
  reducers: {
    setApplicationId: (state: ProductsState, { payload }: PayloadAction<SetApplicationIdPayload>) => {
      state.applicationId = payload;
    },
    setProducts: (state: ProductsState, { payload }: PayloadAction<ProductsPayload>) => {
      state.products = payload;
    },
    setQuotes: (state: ProductsState, { payload }: PayloadAction<QuotesPayload>) => {
      state.quotes = payload;
    },
    setIsLoadingProducts: (state: ProductsState, { payload }: PayloadAction<SetIsLoadingPayload>) => {
      state.isLoadingProducts = payload;
    },
    setIsLoadingQuotes: (state: ProductsState, { payload }: PayloadAction<SetIsLoadingPayload>) => {
      state.isLoadingQuotes = payload;
    },
    resetQuotes: (state: ProductsState) => {
      state.quotes = defaultState.quotes;
    },
    reset: (state: ProductsState) => {
      state.applicationId = undefined;
      state.products = defaultState.products;
      state.quotes = defaultState.quotes;
      state.isLoadingProducts = defaultState.isLoadingProducts;
      state.isLoadingQuotes = defaultState.isLoadingQuotes;
    },
  },
});
