import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { useLocale, useDispatch } from '../../Hooks';

import { QueryId } from '../../ReactQuery/common/common.types';
import {
  fetchCarrierQuestionnaire,
  CarrierQuestionnaireResponse,
  fetchCarrierQuestionnaires,
} from '../../Services/CarrierQuestionnaireService';
import { notificationSlice } from '../../ReduxStore/Notification/NotificationSlice';
import { useTranslation } from 'react-i18next';
import { logger } from '@breathelife/monitoring-frontend';

export function useFetchCarrierQuestionnaireQuery(
  applicationId?: string,
  options?: UseQueryOptions<CarrierQuestionnaireResponse | undefined, Error>,
): UseQueryResult<CarrierQuestionnaireResponse | undefined, Error> {
  const language = useLocale();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  return useQuery<CarrierQuestionnaireResponse | undefined, Error>(
    [QueryId.carrierQuestionnaire, applicationId, language],
    async () => {
      if (!applicationId) return;
      return await fetchCarrierQuestionnaire(applicationId);
    },
    {
      ...options,
      enabled: !!applicationId,
      staleTime: 1000 * 60 * 30, // 30 minutes
      onError: () =>
        dispatch(
          notificationSlice.actions.setError({
            message: t('notifications.failedToFetchCarrierQuestionnaire'),
          }),
        ),
    },
  );
}

export function useFetchAllCarrierQuestionnairesQuery(
  options?: UseQueryOptions<CarrierQuestionnaireResponse[]>,
): UseQueryResult<CarrierQuestionnaireResponse[]> {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  return useQuery<CarrierQuestionnaireResponse[]>(
    [QueryId.allQuestionnairesNodeIds],
    async () => {
      return await fetchCarrierQuestionnaires();
    },
    {
      ...options,
      onError: (err) => {
        dispatch(
          notificationSlice.actions.setError({
            message: t('notifications.failedToFetchEntity', {
              entity: t('admin.questionnaireManagement.nodeIds'),
            }),
          }),
        );
        logger.error(err);
        options?.onError?.(err);
      },
    },
  );
}
