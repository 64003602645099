import { InsuranceEntities } from '@breathelife/insurance-entities';
import { NodeIds } from '@breathelife/insurance-form-builder';
import { CollectionInstanceIdentifiers, VersionedAnswers } from '@breathelife/types';

export type ProposedInsuredTab = {
  name?: string;
  index: number;
  surrogateId?: string;
};

export function getInsuredPeopleTabs(
  insuranceEntities: InsuranceEntities,
  answers: VersionedAnswers,
  getRepetitionCount: (
    answers: VersionedAnswers,
    nodeId: string,
    collectionInstanceIdentifiers: CollectionInstanceIdentifiers,
  ) => number | undefined,
): ProposedInsuredTab[] {
  const tabs: ProposedInsuredTab[] = [];
  if (!answers.v1) return tabs;

  let insuredPeopleCount = 1;

  try {
    // TODO: FIXME: HOT-3599 remove NodeIds usage
    const repetitionCount = getRepetitionCount(answers, NodeIds.insuredPeople, {});
    if (repetitionCount) {
      insuredPeopleCount = repetitionCount;
    }
  } catch (error: any) {
    // 'insured-people' node was not an array
    return tabs;
  }

  for (let index = 0; index < insuredPeopleCount; index++) {
    const tabData: ProposedInsuredTab = { index };

    try {
      const proposedInsured = insuranceEntities.getProposedInsured(answers.v1, index);
      if (proposedInsured) {
        const { firstName, lastName, surrogateId } = proposedInsured;
        if (firstName || lastName) {
          tabData.name = [firstName, lastName].filter(Boolean).join(' ');
        }
        tabData.surrogateId = surrogateId;
      }
    } catch (error: any) {
      // entity generator has failed but let's still add a new index
    } finally {
      tabs.push(tabData);
    }
  }

  return tabs;
}
